import React, { useState, useEffect } from 'react';
import {
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
  CDataTable,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
} from '@coreui/react';
import { FilePreviewModal } from '../../modals';
import { CustButton } from 'src/reusable';
import Modal from './modal';
import Loader from 'src/views/common/loader'
import ModalAccessManagement from './modal_access_management';
import ModalSendingWelcomeMail from './modal_sending_welcome_mail';
import DateRangePicker from 'src/views/common/dateRangePicker';
import moment from 'moment';
// import { config } from 'src/utils/apiUrl'
// import { API } from 'src/utils/apiCalling'
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import swal from 'sweetalert';
import { toast, ToastContainer } from 'react-toastify';
import ViewDetails from './modal1';
import ViewDetails1 from './modal2';
import { isPermission } from 'src/utils/common';
const CandidateAccessManagment = props => {
  const { candidateAccessManagement, newCandidateAccessManagement } = props;

  let api = new API();

  const [iDCardDetails, setIDCardDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [active, setActive] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [empUserID, setEmpUserID] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const [finalJoined, setFinalJoined] = useState();
  const [generatedEmpId, setGeneratedEmpId] = useState();
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [appointmentLetterFileData, setappointmentLetterFileData] = useState('');
  const [isShowAppointmentLetter, setIsAppointmentLetter] = useState(false)
  const [appointmentLetterData, setappointmentLetterData] = useState("")
  const [loadingState, setLoadingState] = useState(false);



  useEffect(() => {
    getFinalJoinedCandidate();
  }, [candidateAccessManagement]);
  const getFinalJoinedCandidate = async () => {
    let result = await api.get(config.totalCandidateJoined);
    if (result && result.code === 200) {
      setFinalJoined(result.data);
    } else {
    }
  };

  const isViewModalOpen = candidate => {
    setIsModalOpen(!isModalOpen);
    setCandidateDetails(candidate);
  };
  const isViewModalOpen1 = candidate => {
    setIsModalOpen1(!isModalOpen1);
    setCandidateDetails(candidate);
  };

  const addEmployeeEmpId = async (id, emp_id, email_type) => {
    let data = {
      emp_user_id: id,
      emp_id: emp_id,
      email_type: email_type
    };
    swal({
      content: true,
      title: 'Are you sure?',
      text: `Are you sure to add Employee ID`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updateEmpIdByHR, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          setIsModalOpen1(false);
          newCandidateAccessManagement();
        } else {
          toast.warning(result && result.message);
        }
      }
    });
  };
  const generateEmployeeId = async type => {
    let data = {
      type: type,
    };

    let result = await api.get(config.generateEmployeeId, true, data);
    if (result && result.code === 200 && result.data) {
      setGeneratedEmpId(result.data.generatedEmpId);
    } else {
      toast.warning('Unable to generate please enter manually');
    }
  };
  const sendWelcomeMailFun = async data => {
    setSendEmailModal(!sendEmailModal);
    setSelectedCandidate(data);
  };

  useEffect(() => {
    getAllIDCardRequest();
  }, []);
  const getAllIDCardRequest = async () => {
    let result = await api.get(config.getAllIDCardDetails);
    if (result && result.code === 200) {
      setIDCardDetails(result.data);
    }
  };
  const isViewModalOpen2 = emp_user_id => {
    setEmpUserID(emp_user_id);
    setIsOpen(!isOpen);
  };

  const isViewModalOpen3 = emp_user_id => {
    setEmpUserID(emp_user_id);
    setIsOpen1(!isOpen1);
  };

  const updateAdminStaus = async emp_user_id => {
    let data = {
      emp_user_id: emp_user_id,
    };
    swal({
      html: true,
      title: 'Are you sure?',
      text: `You want to assign the ID card request to Admin`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Assign'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.assignToAdmin, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          getAllIDCardRequest();
        } else {
          toast.warning(result.message);
        }
      }
    });
  };

  const fields = [
    { label: 'Emp ID', key: 'emp_id', _style: { width: '5%' } },
    { label: 'Employee Name', key: 'employee_name', _style: { width: '10%' } },
    { label: 'Email ID', key: 'email', _style: { width: '10%' } },
    { label: 'Mobile No.', key: 'mobile', _style: { width: '8%' } },
    { label: 'Department', key: 'department', _style: { width: '10%' } },
    { label: 'Designation', key: 'designation', _style: { width: '10%' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
    {
      key: 'assign',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ];
  const fields1 = [
    { label: 'Emp ID', key: 'emp_id', _style: { width: '5%' } },
    { label: 'Employee Name', key: 'employee_name', _style: { width: '10%' } },
    { label: 'Email ID', key: 'email', _style: { width: '10%' } },
    { label: 'Mobile No.', key: 'mobile', _style: { width: '8%' } },
    { label: 'Department', key: 'department', _style: { width: '10%' } },
    {
      label: 'Expected Issue Date',
      key: 'expected_issue_date',
      _style: { width: '10%' },
    },
    { label: 'Issue Card At', key: 'issued_at', _style: { width: '10%' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },
  ];
  const  pendingCandidate =[
    { label: 'S.No', key: 's_no', _style: { width: '5%' } },
    { label: 'Emp ID', key: 'emp_id', _style: { width: '5%' } },
    { label: 'Employee Name', key: 'name', _style: { width: '15%' } },
    { label: 'Email ID', key: 'email', _style: { width: '10%' } },
    { label: 'Mobile No.', key: 'mobile', _style: { width: '8%' } },
    { label: 'Department', key: 'department', _style: { width: '10%' } },
    { label: 'Designation', key: 'designation', _style: { width: '10%' } },
    // { label: 'MRF Id', key: 'mrf_id', _style: { width: '10%' } },
    // {
    //   label: 'MRF Applying For',
    //   key: 'mrf_applying_for',
    //   _style: { width: '10%' },
    // },
    // { label: 'Overall Interview Status', key: 'overall_interview_status', _style: { width: '15%' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },

  ]
  const  assignedCandidate =[
    { label: 'S.No', key: 's_no', _style: { width: '3%' } },
    { label: 'Emp ID', key: 'emp_id', _style: { width: '5%' } },
    { label: 'Employee Name', key: 'name', _style: { width: '12%' } },
    { label: 'Email ID', key: 'email', _style: { width: '10%' } },
    { label: 'Mobile No.', key: 'mobile', _style: { width: '8%' } },
    { label: 'Designation', key: 'designation', _style: { width: '10%' } },
    { label: 'MRF Id', key: 'mrf_id', _style: { width: '10%' } },
    {
      label: 'MRF Applying For',
      key: 'mrf_applying_for',
      _style: { width: '15%' },
    },
    { label: 'Overall Interview Status', key: 'overall_interview_status', _style: { width: '15%' } },
    // {
    //   key: 'show_details',
    //   label: '',
    //   _style: { width: '5%' },
    //   sorter: false,
    //   filter: false,
    // },

  ]
  const  appointmentLatter =[
    { label: 'S.No', key: 's_no', _style: { width: '3%' } },
    { label: 'Emp ID', key: 'emp_id', _style: { width: '5%' } },
    { label: 'Employee Name', key: 'name', _style: { width: '12%' } },
    { label: 'Email ID', key: 'email', _style: { width: '10%' } },
    { label: 'Mobile No.', key: 'mobile', _style: { width: '8%' } },
    { label: 'Designation', key: 'designation', _style: { width: '10%' } },
    // { label: 'MRF Id', key: 'mrf_id', _style: { width: '10%' } },
    // {label: 'MRF Applying For', key: 'mrf_applying_for',_style: { width: '15%' },},
    { key: 'show_details',label: 'Action',_style: { width: '5%' },sorter: false,filter: false,},

  ]

  const downalodExcelReports = async () => {
    let data = {
      start_date: startDate,
      end_date: endDate,
    };
    let result = await api.get(config.downloadReportForIDCard, true, data);
    if (result && result.code === 200) {
      window.open(result.data.filePath, '_blank');
      toast.success(result.message);
    } else {
      toast.warning(result.message);
    }
  };
  const sendAppointmentLattera = () => {
    sendAppointmentLatter(appointmentLetterData, false)
  }
  const sendAppointmentLatter = async (value, is_preview) => {
    setLoadingState(true)
    setappointmentLetterData(value)
    let data = {
      emp_id: value?.emp_id,
      is_preview: is_preview
    }
    let result = await api.post(config.sendAppointmentLetterToCandidate, data)
    if (result && result.code == 200) {
      setIsAppointmentLetter(is_preview)
      setLoadingState(false)
      setappointmentLetterFileData({
        filename: '',
        payload: result?.data?.filePath || '',
        mime: 'pdf',
      });

    } else {
      toast.warning(result.message);
      setLoadingState(false)
    }
  }

  return (
    <div className="">
      <div className="container">
        <h3 className="text-uppercase text-center mt-3 mb-4">
          {' '}
          <u>New Candidate Access Management</u>
        </h3>
        <CRow>
          <CCol xs="12">
            <CCard>
              <CCardHeader>New Candidate</CCardHeader>
              <CCardBody>
                <CTabs
                  activeTab={active}
                  onActiveTabChange={idx => setActive(idx)}
                >
                  
                  <CNav variant="tabs">
                    {/* <CNavItem>
                      <CNavLink>Pending Candidate Emp Id</CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink>Assigned Candidate Emp Id</CNavLink>
                    </CNavItem> */}
                    <CNavItem>
                      <CNavLink>Appointment Letter</CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink>Welcome Mail</CNavLink>
                    </CNavItem>
                    {/* <CNavItem>
                      <CNavLink>Pending ID Card Request</CNavLink>
                    </CNavItem>
                    <CNavItem>
                      <CNavLink>Assigned ID Card Request</CNavLink>
                    </CNavItem> */}
                  </CNav>
                 
                  <CTabContent>
                  {/* Pending Candidate Emp Id */}
                    {/* <CTabPane>
                     
                      <CDataTable
                        items={
                          candidateAccessManagement &&
                          candidateAccessManagement
                            .filter(
                              candidate =>
                                candidate.emp_id_created_status === 0,
                            ) 
                            .map((data, index) => data)
                        }
                        fields={pendingCandidate}
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={10}
                        hover
                        pagination
                        scopedSlots={{
                          s_no:(item,index )=>{
                            return ( <td>{index +1}</td>)
                          },
                          mrf_id:item=>{
                          return ( <td>{item.mrf_id?item.mrf_id:''}</td>)
                          },
                          mrf_applying_for:item=>{
                            return <td>{item.mrf_applying_for === 'vacancy'
                            ? 'New Hiring'
                            : item.mrf_applying_for}</td>
                          },
                          overall_interview_status:item=>{
                            return(<td>
                              {item.overall_interview_status === 1
                                ? 'Selected'
                                : 'Not Selected'}
                            </td>)
                          },
                          show_details: item => {
                            return (
                            
                              <div>
                              <td>
                                    {isPermission(
                                      'candiate_details_access_management',
                                    ) && (
                                        <CButton
                                          data-toggle="tooltip"
                                          title="View"
                                          className="btn btn-primary mr-0"
                                          onClick={() => isViewModalOpen(item)}
                                        >
                                          <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i>
                                        </CButton>
                                      )}
                                  </td>
                                  <td>
                                    {isPermission('add_emp_id') && (
                                      <CButton
                                        className="btn btn-success mr-0"
                                        onClick={() => isViewModalOpen1(item)}
                                      >
                                        Add Emp ID
                                      </CButton>
                                    )}
                                  </td>
                                  </div>
                            );
                          },
                          
               
                        }}
                      />
                    </CTabPane> */}
                    {/* Assigned Candidate Emp Id */}
                    
                    {/* <CTabPane>
                     


                      <CDataTable
                        items={
                          candidateAccessManagement &&
                          candidateAccessManagement
                            .filter(
                              candidate =>
                                candidate.emp_id_created_status === 0,
                            ) 
                            .map((data, index) => data)
                        }
                        fields={assignedCandidate}
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={10}
                        hover
                        pagination
                        scopedSlots={{
                          s_no:(item,index )=>{
                            return ( <td>{index +1}</td>)
                          },
                          mrf_id:item=>{
                          return ( <td>{item.mrf_id?item.mrf_id:''}</td>)
                          },
                          mrf_applying_for:item=>{
                            return <td>{item.mrf_applying_for === 'vacancy'
                            ? 'New Hiring'
                            : item.mrf_applying_for}</td>
                          },
                          overall_interview_status:item=>{
                            return(<td>
                              {item.overall_interview_status === 1
                                ? 'Selected'
                                : 'Not Selected'}
                            </td>)
                          },
                          // show_details: item => {
                          //   return (
                            
                          //     <div>
                          //       <td>
                          //           {isPermission(
                          //             'candiate_details_access_management',
                          //           ) && (
                          //               <CButton
                          //                 data-toggle="tooltip"
                          //                 title="View"
                          //                 className="btn btn-primary mr-0"
                          //                 onClick={() => isViewModalOpen(item)}
                          //               >
                          //                 <i
                          //                   className="fa fa-eye"
                          //                   aria-hidden="true"
                          //                 ></i>
                          //               </CButton>
                          //             )}
                          //         </td>
                          //         <td>
                          //           {isPermission('add_emp_id') && (
                          //             <CButton
                          //               className="btn btn-success mr-0"
                          //               onClick={() => isViewModalOpen1(item)}
                          //             >
                          //               Add Emp ID
                          //             </CButton>
                          //           )}
                          //         </td>
                          //     </div>
                          //   );
                          // },
                          
               
                        }}
                      />
                    </CTabPane> */}

                    {/* Appointment Letter */}

                    <CTabPane>
                      <CDataTable
                        items={
                          candidateAccessManagement &&
                          candidateAccessManagement
                            .filter(
                              candidate =>
                                candidate.account_status === "active",
                            ) 
                            .map((data, index) => data)
                        }
                        fields={appointmentLatter}
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={10}
                        hover
                        pagination
                        scopedSlots={{
                          s_no:(item,index )=>{
                            return ( <td>{index +1}</td>)
                          },
                          mrf_id:item=>{
                          return ( <td>{item.mrf_id?item.mrf_id:''}</td>)
                          },
                          mrf_applying_for:item=>{
                            return <td>{item.mrf_applying_for === 'vacancy'
                            ? 'New Hiring'
                            : item.mrf_applying_for}</td>
                          },
                        
                          show_details: item => {
                            return (
                              <td>
                              <div className='d-flex'>
                              <CButton
                                type="button"
                                name="ok"
                                // color="primary"
                                onClick={() => sendAppointmentLatter(item, true)}
                                className="btn btn-success mr-2"
                                // icon="fa-download"
                              >
                              {item.send_appointment_letter_count > 0 ? "Re-Send Letter" : "Send Letter"}
                              </CButton>
                              {item.accept_appointment_letter > 0 &&
                              <CButton
                              type="button"
                              name="ok"
                              // color="primary"
                              disabled
                              onClick={() => sendAppointmentLatter(item, true)}
                              className="btn btn-success mr-0"
                              // icon="fa-download"
                            >
                            Accepted
                            </CButton>
                              
                              }
                              </div>
                              

                              {/* <CButton data-toggle="tooltip" title="View" className="btn btn-success mr-0" onClick={() => sendAppointmentLatter(data, true)} >{data.send_appointment_letter_count > 0 ? "Re-Send Letter" : "Send Letter"}</CButton> */}
                            </td>
                              // <div>
                              //   <td>
                              //       {isPermission(
                              //         'candiate_details_access_management',
                              //       ) && (
                              //           <CButton
                              //             data-toggle="tooltip"
                              //             title="View"
                              //             className="btn btn-primary mr-0"
                              //             onClick={() => isViewModalOpen(item)}
                              //           >
                              //             <i
                              //               className="fa fa-eye"
                              //               aria-hidden="true"
                              //             ></i>
                              //           </CButton>
                              //         )}
                              //     </td>
                              //     <td>
                              //       {isPermission('add_emp_id') && (
                              //         <CButton
                              //           className="btn btn-success mr-0"
                              //           onClick={() => isViewModalOpen1(item)}
                              //         >
                              //           Add Emp ID
                              //         </CButton>
                              //       )}
                              //     </td>
                              // </div>
                            );
                          },
                          
               
                        }}
                      />


                    

                    </CTabPane>


                    {/* welcome mail  */}


                    <CTabPane>
                    


                      <CDataTable
                        items={
                          candidateAccessManagement &&
                          candidateAccessManagement
                            .filter(
                              candidate =>
                                  candidate.emp_id_created_status === 1 &&
                                  candidate.email_id_created_status === 1 &&
                                  candidate.send_welcome_letter === 0,
                            ) 
                            .map((data, index) => data)
                        }
                        fields={pendingCandidate}
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={10}
                        hover
                        pagination
                        scopedSlots={{
                          s_no:(item,index )=>{
                            return ( <td>{index +1}</td>)
                          },
                          mrf_id:item=>{
                          return ( <td>{item.mrf_id?item.mrf_id:''}</td>)
                          },
                          mrf_applying_for:item=>{
                            return <td>{item.mrf_applying_for === 'vacancy'
                            ? 'New Hiring'
                            : item.mrf_applying_for}</td>
                          },
                          overall_interview_status:item=>{
                            return(<td>
                              {item.overall_interview_status === 1
                                ? 'Selected'
                                : 'Not Selected'}
                            </td>)
                          },
                          show_details: item => {
                            return (
                            
                              <div>
                              <td>
                                    {isPermission(
                                      'candiate_details_access_management',
                                    ) && (
                                        <CButton
                                          data-toggle="tooltip"
                                          title="View"
                                          className="btn btn-primary mr-0"
                                          onClick={() => isViewModalOpen(item)}
                                        >
                                          <i
                                            className="fa fa-eye"
                                            aria-hidden="true"
                                          ></i>
                                        </CButton>
                                      )}
                                  </td>
                                  <td>
                                    {isPermission('send_welcome_mail') &&
                                      <CButton
                                        className="btn btn-success mr-0"
                                        onClick={() => sendWelcomeMailFun(item)}
                                      >
                                        Send Welcome Mail
                                      </CButton>
                                    }
                                  </td>
                                  </div>
                            );
                          },
                          
               
                        }}
                      />
                    </CTabPane>
                    <CTabPane>
                      <CDataTable
                        items={
                          iDCardDetails &&
                          iDCardDetails
                            .filter(
                              candidate =>
                                candidate.assigned_to_admin_status === 0,
                            )
                            .map((data, index) => data)
                        }
                        fields={fields}
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={5}
                        hover
                        pagination
                        scopedSlots={{
                          show_details: item => {
                            return (
                              <td>
                                {isPermission('update_id_card_details') &&
                                  <CButton
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="View"
                                    block
                                    className="btn btn-primary"
                                    onClick={() =>
                                      isViewModalOpen2(item.emp_user_id)
                                    }
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </CButton>
                                }
                              </td>
                            );
                          },
                          assign: item => {
                            return (
                              <td>
                                {isPermission('assign_id_card') &&
                                  <CButton
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Assign to admin"
                                    block
                                    className="btn btn-success"
                                    onClick={() =>
                                      updateAdminStaus(item.emp_user_id)
                                    }
                                  >
                                    <i
                                      className="fa fa-paper-plane"
                                      aria-hidden="true"
                                    ></i>
                                  </CButton>
                                }
                              </td>
                            );
                          },
                        }}
                      />
                    </CTabPane>
                    <CTabPane>
                      {isPermission('download_id_card_report') &&
                        <div className="btn btn-group float-center">
                          <DateRangePicker
                            DateStart={setStartDate}
                            DateEnd={setEndDate}
                          />
                          <div
                            className="btn btn-group float-center"
                            style={{ display: 'initial' }}
                          >
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={downalodExcelReports}
                            >
                              Download ID Card Report&nbsp;
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      }
                      <CDataTable
                        items={
                          iDCardDetails &&
                          iDCardDetails
                            .filter(
                              candidate =>
                                candidate.assigned_to_admin_status === 1,
                            )
                            .map((data, index) => data)
                        }
                        fields={fields1}
                        tableFilter
                        itemsPerPageSelect
                        itemsPerPage={5}
                        hover
                        pagination
                        scopedSlots={{
                          show_details: item => {
                            return (
                              <td>
                                {isPermission('view_id_card_hr') &&
                                  <CButton
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="View"
                                    block
                                    className="btn btn-primary"
                                    onClick={() =>
                                      isViewModalOpen3(item.emp_user_id)
                                    }
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </CButton>
                                }
                              </td>
                            );
                          },
                        }}
                      />
                    </CTabPane>
                  </CTabContent>
                </CTabs>
                <CCardFooter>
                  <CButton
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Back"
                    color="success"
                  >
                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                  </CButton>{' '}
                </CCardFooter>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <ViewDetails1
        isOpen1={isOpen1}
        isViewModalOpen3={isViewModalOpen3}
        empUserID={empUserID}
      />

      <ViewDetails
        isOpen={isOpen}
        isViewModalOpen2={isViewModalOpen2}
        empUserID={empUserID}
      />

      <Modal
        isModalOpen={isModalOpen}
        message="Candidate Details"
        isViewModalOpen={isViewModalOpen}
        candidateDetails={candidateDetails}
      />

      <ModalAccessManagement
        isModalOpen={isModalOpen1}
        message="Generate Candidate Employee ID"
        isViewModalOpen={isViewModalOpen1}
        candidateDetails={candidateDetails}
        addEmployeeEmpId={addEmployeeEmpId}
        generatedEmpId={generatedEmpId}
        generateEmployeeId={generateEmployeeId}
      />

      <ModalSendingWelcomeMail
        isModalOpen={sendEmailModal}
        isViewModalOpen={sendWelcomeMailFun}
        setSendEmailModal={setSendEmailModal}
        selectedCandidate={selectedCandidate}
      />

      <FilePreviewModal
        isOpen={isShowAppointmentLetter}
        toggleModal={() => {
          setIsAppointmentLetter(false);
        }}
        fileData={appointmentLetterFileData}
        showSendOfferLetterBtn={true}
        handleSendOfferLetter={sendAppointmentLattera}
      />
      {loadingState === true &&<div className='LoaderItem'> <Loader /></div>}
      <ToastContainer />
    </div>
  );
};
export default CandidateAccessManagment;
